<template>
  <div class="warningInfo">
    <Breadcrumb :title="$route.meta.title" />
    <div class="searchWrap">
      <!-- <div class="typeNewsSelect">
        <label>產業別：</label>
        <el-select v-model="newsSelectValue" @change="changeTypeNews" placeholder="請選擇新聞種類" no-data-text="目前無名單">
          <el-option v-for="item in newsTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div> -->
      
      <section v-if="newsSelectValue==='searchNews'" class="searchNews">
        <div class="searchInputs">
          <div class="itemWrap">
            <label>查詢日期：</label>
            <el-date-picker type="daterange" :editable="false" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="開始日期" 
              end-placeholder="結束日期"
              v-model="rangeDate"
            ></el-date-picker>
          </div>
          <div class="itemWrap">
            <label>產業別：</label>
            <el-select v-model="listQuery.IndustryCategory" placeholder="請選擇產業別" no-data-text="目前無名單" clearable>
              <el-option v-for="(item, key) in indusSelections.selection" :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>產業次類別：</label>
            <el-select v-model="listQuery.IndustrySubCategory" placeholder="請選擇產業次類別" no-data-text="目前無名單" clearable>
              <el-option v-if="listQuery.IndustryCategory" v-for="(item,key) in currentIndusSelectionsSub" :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>產業小類別：</label>
            <el-select v-model="listQuery.IndustrySmallCategory" placeholder="請選擇產業小類別" no-data-text="目前無名單" clearable>
              <el-option v-if="listQuery.IndustrySubCategory" v-for="(item,key) in currentIndusSelectionsSmall" :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>產業鏈：</label>
            <el-select v-model="listQuery.indStream" placeholder="產業鏈" no-data-text="目前無名單" clearable>
              <el-option v-for="(item,index) in curIndStreamOpts" :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>產業描敘：</label>
            <el-select v-model="listQuery.indDesc" placeholder="產業描敘" no-data-text="目前無名單" clearable>
              <el-option v-for="(item,index) in curIndDescOpts" :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>產業分類：</label>
            <el-select v-model="listQuery.indCate" placeholder="產業分類" no-data-text="目前無名單" clearable>
              <el-option v-for="(item,index) in curIndCateOpts" :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>產品結構：</label>
            <el-select v-model="listQuery.prodStruc" placeholder="產品結構" no-data-text="目前無名單" clearable>
              <el-option v-for="(item,index) in curProdStrucOpts" :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <!-- <div class="itemWrap">
            <label>新聞關鍵字：</label>
            <el-input v-model.trim="listQuery.SearchContent" placeholder="請輸入新聞關鍵字"></el-input>
          </div> -->
        </div>
        <div class="relatedInd" v-if="!rightEmptyPanel" style="max-height: 500px; overflow-y: scroll;">
          <table border="1" style="background: #fff; border-collapse: collapse;" v-if="currentUpstream">
            <tr height="50">
              <th width="150">上游</th>
              <th width="150">中游</th>
              <th width="150">下游</th>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                <div v-for="(item,index) in currentUpstream" v-if="item.descname == 'empty'">
                  <div v-for="(cate,index) in item.selection">
                    <h5>{{cate.catename}}</h5>
                    <el-button type="info" style="margin-top: 5px;" v-for="subcate in cate.selection">{{subcate}}</el-button>
                  </div>
                </div>
                <div v-for="outsideItem in currentUpstream" v-if="outsideItem.descname != 'empty'">
                  <el-collapse v-model="activeCollapsePanelNames">
                    <el-collapse-item v-for="(item,index) in currentUpstream" :title="item.descname == 'empty'?'無產業描述':item.descname" :name="'up'+index">
                      <div v-for="(cate,index) in item.selection">
                        <h5>{{cate.catename}}</h5>
                        <el-button type="info" style="margin-top: 5px;" v-for="subcate in cate.selection">{{subcate}}</el-button>
                      </div>

                    </el-collapse-item>
                  </el-collapse>
                </div>
              </td>
              <td style="vertical-align: top;">
                <div v-for="(item,index) in currentMidstream" v-if="item.descname == 'empty'">
                  <div v-for="(cate,index) in item.selection">
                    <h5>{{cate.catename}}</h5>
                    <el-button type="info" style="margin-top: 5px;" v-for="subcate in cate.selection">{{subcate}}</el-button>
                  </div>
                </div>
                <div v-for="outsideItem in currentMidstream" v-if="outsideItem.descname != 'empty'">
                  <el-collapse v-model="activeCollapsePanelNames">
                    <el-collapse-item v-for="(item,index) in currentMidstream" :title="item.descname == 'empty'?'無產業描述':item.descname" :name="'mid'+index">
                      <div v-for="(cate,index) in item.selection">
                        <h5>{{cate.catename}}</h5>
                        <el-button type="info" v-for="subcate in cate.selection">{{subcate}}</el-button>
                      </div>
                    </el-collapse-item>
                  </el-collapse> 
                </div> 
              </td>
              <td style="vertical-align: top;">
                <div v-for="(item,index) in currentDownstream" v-if="item.descname == 'empty'">
                  <div v-for="(cate,index) in item.selection">
                    <h5>{{cate.catename}}</h5>
                    <el-button type="info" style="margin-top: 5px;" v-for="subcate in cate.selection">{{subcate}}</el-button>
                  </div>
                </div>
                <div v-for="outsideItem in currentDownstream" v-if="outsideItem.descname != 'empty'">
                  <el-collapse v-model="activeCollapsePanelNames">
                    <el-collapse-item v-for="(item,index) in currentDownstream" :title="item.descname == 'empty'?'無產業描述':item.descname" :name="'down'+index">
                      <div v-for="(cate,index) in item.selection">
                        <h5>{{cate.catename}}</h5>
                        <el-button type="info" v-for="subcate in cate.selection">{{subcate}}</el-button>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="relatedInd" v-if="rightEmptyPanel">
          <ul>
            <li>產業分類</li>
            <li>
              <el-button type="info" v-for="(item,index) in currentEmptyStream" :key="item" :value="item">{{item}}</el-button>
            </li>
          </ul>
        </div>
        
      </section>
    

      

      <div class="btnWrap"> 
        <el-button type="primary" @click="handleSearch">查詢</el-button>
        <!-- <div class="addButton" v-if="isOperator">
          <el-button type="primary" @click="toggleDialog">
            <font-awesome-icon icon="plus" style="margin-right: 5px"/>建立警示名單
          </el-button>
          <el-button type="primary" @click="editRelatedNews">
            <font-awesome-icon icon="plus" style="margin-right: 5px"/>加入關聯資訊
          </el-button>
        </div> -->
      </div>

    </div>

    
    <div class="listWrap__content">
      <!-- *負面新聞資料* -->
      <el-table v-if="typeNews == 'searchNews'" style="width: 100%" ref="multipleTable"
        :data="tableData"
        @select="getSelectedRow"
      >
        <el-table-column type="selection" width="60" v-if="isOperator"></el-table-column>
        <el-table-column prop="CorpTitle" label="公司名稱" width="200">
          <template slot-scope="scope">
            <a class="goDetailPage" @click="previewDetailPage(scope.row,'detailNews')">{{
              scope.row.NewsTitle
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="NewsTitle" label="新聞標題" width="400">
          <template slot-scope="scope">
            <a class="goDetailPage" @click="previewDetailPage(scope.row,'detailNews')">{{
              scope.row.NewsTitle
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="NewsTime" label="新聞時間" width="300">
          <template slot-scope="scope">
            <div>{{ filterDate(scope.row.NewsTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="NewsSite" label="新聞網站" width="150"></el-table-column>
        <el-table-column prop="NewsChannel" label="新聞頻道" width="150"> </el-table-column>
        <el-table-column prop="Sentiment" label="情緒指標">
          <template slot-scope="scope">
            {{ transSentimentName(scope.row.Sentiment) }}
          </template>
        </el-table-column>
      </el-table>
      
    </div>

    <div v-if="openPopUp" class="WarningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="addWarningForm('people','add')">人名</el-button>
          <el-button type="danger" @click="addWarningForm('org','add')">組織</el-button>
        </div>
      </div>
    </div>
    <Pagination
      @currentChange="pageChange"
      :pageSize="this.listQuery.Page_Size"
      :propsCurrentPage="this.listQuery.Page_Num"
      :totalLists="totalLists"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
const requestData = {
  //共用
  Start_Date: '',
  End_Date: '',
  SearchContent:"",//關鍵字
  NewsID: '',
  Page_Size: 10, //每頁有幾筆
  Page_Num: 1, //目前頁數
  IndustryCategory:[],
  IndustrySubCategory: [],
  IndustrySmallCategory: [],
  IndustryLinks: [],
  indStream: [],
  indDesc: [],
  indCate: [],
  prodStruc: [],

  Site:[],
  Sentiment:[],

  //聯合國
  ListID:[],
  Type: '',
  //其餘新聞種類
}
export default {
  components: {Breadcrumb,Pagination},
  data() {
    return {
      placeholder:"",
      typeNews: 'searchNews',
      activeCollapsePanelNames: ['1'],
      rightEmptyPanel: false,
      newsTypeOptions: [
        {
          value: 'searchNews',
          label: '負面新聞資料',
        },
        {
          value: 'searChsan',
          label: '聯合國制裁名單',
        },
        {
          value: 'searchConstr',
          label: '政府拒絕往來廠商名單',
        },
        {
          value: 'searchLabor',
          label: '違反勞動法名單',
        },
        {
          value: 'searchEnviroment',
          label: '環保署裁罰處分名單',
        },
      ],
      sentimentOption: [
        {
          value: 'positive',
          label: '正向',
        },
        {
          value: 'neutral',
          label: '中立',
        },
        {
          value: 'negative',
          label: '負向',
        },
      ],
      typeOptions: [
        {
          value: 'INDIVIDUAL',
          label: '人員',
        },
        {
          value: 'ENTITY',
          label: '組織',
        },
      ],
      listOptions: [
        {
          value: 'ListID',
          label: '名單編號',
        },
        {
          value: 'Name',
          label: '名單名稱',
        },
      ],
      totalLists: null,
      openPopUp:false,
      searChsanInputListID:"",
      searChsanSearchType:"",
      webSiteOptions: [],
      indusSelections: [],
      currentIndusSelectionsSub: [],
      currentIndusSelectionsSmall: [],
      currentUpstream: [],
      currentMidstream: [],
      currentDownstream: [],
      currentEmptystream: [],
      curIndStreamOpts: [],
      curIndDescOpts: [],
      curIndCateOpts: [],
      curProdStrucOpts: [],
      newsSelectValue:"searchNews",
      rangeDate:[],
      listQuery:JSON.parse(JSON.stringify(requestData)),
      tableData: [],
      relationshipBtnTableData: [{
        ups: "",
        mids: "",
        downs: ""
      }],
      userRole: this.$store.state.userInfo.Role,
      multipleSelection:[],
      manageAllSelection:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
    };
  },
  computed: {
    composeName(row){
      console.log(row);
    },
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },
    transSentimentName(){
      return (En) => {
        let transName = "無";
        if(En){
          switch(En){
            case 'positive':
              transName = '正向'
              break
            case 'negative':
              transName = '負向'
              break
            case 'neutral':
              transName = '中立'
              break
          }
        }
        return transName;
      };
    },
    isOperator(){
      let result = null;
      if(this.userRole==='operator'){
        result = true
      }else{
         result = false
      }
      // return result
      return true
    },
  },
  watch: {
    "listQuery.IndustryCategory": {
      handler: function(val, oldVal) {
        const _this = this;
        this.changeCurrentIndusSelectionsSub(_this.listQuery.IndustryCategory);
      },
      deep: true
    },
    "listQuery.IndustrySubCategory": {
      handler: function(val, oldVal) {
        const _this = this;
        this.changeCurrentIndusSelectionsSmall(_this.listQuery.IndustryCategory, _this.listQuery.IndustrySubCategory);
        
      },
      deep: true
    },
    "listQuery.IndustrySmallCategory": {
      handler: function(val, oldVal) {
        const _this = this;
        this.changeCurrentIndusSelectionsStream(_this.listQuery.IndustryCategory, _this.listQuery.IndustrySubCategory, _this.listQuery.IndustrySmallCategory);
        this.setDropDown("curIndStreamOpts",4);
      },
      deep: true
    },
    "listQuery.indStream": {
      handler: function(val, oldVal) {
        const _this = this;
        this.setDropDown("curIndDescOpts",5);
      },
      deep: true
    },
    "listQuery.indDesc": {
      handler: function(val, oldVal) {
        const _this = this;
        this.setDropDown("curIndCateOpts",6);
      },
      deep: true
    },
    "listQuery.indCate": {
      handler: function(val, oldVal) {
        const _this = this;
        this.setDropDown("curProdStrucOpts",7);
      },
      deep: true
    }
  },
  methods: {

    setDropDown(name,id){
      const _this = this;
      if(id == 4){
        this[name] = _this.indusSelections[_this.listQuery.IndustryCategory][_this.listQuery.IndustrySubCategory][_this.listQuery.IndustrySmallCategory].selection;
      }else if(id == 5){
        this[name] = _this.indusSelections[_this.listQuery.IndustryCategory][_this.listQuery.IndustrySubCategory][_this.listQuery.IndustrySmallCategory][_this.listQuery.indStream].selection;
      }else if(id == 6){
        this[name] = _this.indusSelections[_this.listQuery.IndustryCategory][_this.listQuery.IndustrySubCategory][_this.listQuery.IndustrySmallCategory][_this.listQuery.indStream][_this.listQuery.indDesc].selection;
      }else if(id == 7){
        this[name] = _this.indusSelections[_this.listQuery.IndustryCategory][_this.listQuery.IndustrySubCategory][_this.listQuery.IndustrySmallCategory][_this.listQuery.indStream][_this.listQuery.indDesc][_this.listQuery.indCate].selection;
      }
      
    },
    getIndusSelections() {
      return new Promise((resolve)=>{
        let temp = {
          CreateUniComNm: "1234567",
        };
        this.$api.system.getIndusSelections(temp).then((res) => {
          this.indusSelections = res;
          resolve()
        });
      })
    },
    changeCurrentIndusSelectionsSub(name) {
      // console.log(name);

      if(name != "" && name != undefined){
        // console.log(this.indusSelections[name]);
        this.currentIndusSelectionsSub = this.indusSelections[name].selection;
      }
      
    },
    changeCurrentIndusSelectionsSmall(sup, name) {
      // console.log(name);
      if(sup != "" && sup != undefined && name != "" && name != undefined){
        // console.log(this.indusSelections[name]);
        this.currentIndusSelectionsSmall = this.indusSelections[sup][name].selection;
      }
      
    },
    changeCurrentIndusSelectionsStream(sup, sub, name) { //上中下游
      if(sup != "" && sup != undefined && sub != "" && sub != undefined && name != "" && name != undefined){
        // console.log(this.indusSelections[name]);
        // this.currentIndusSelectionsStream = this.indusSelections[sup][sub][name].selection;
        if(this.indusSelections[sup][sub][name].selection[0] === "empty"){
          this.rightEmptyPanel = true;
          this.currentEmptyStream = this.indusSelections[sup][sub][name]["empty"]["empty"].selection;

        }else{
          this.rightEmptyPanel = false;
          var _this = this;
          this.currentUpstream = this.indusSelections[sup][sub][name]["上游"].selection.map((item)=>{
            return {
              descname: item,
              selection: _this.indusSelections[sup][sub][name]["上游"][item].selection.map((cate)=>{
                return {
                  catename: cate,
                  selection: _this.indusSelections[sup][sub][name]["上游"][item][cate].selection
                };
              })
            };
          });
          this.currentMidstream = this.indusSelections[sup][sub][name]["中游"].selection.map((item)=>{
            return {
              descname: item,
              selection: _this.indusSelections[sup][sub][name]["中游"][item].selection.map((cate)=>{
                return {
                  catename: cate,
                  selection: _this.indusSelections[sup][sub][name]["中游"][item][cate].selection
                };
              })
            };
          });
          this.currentDownstream = this.indusSelections[sup][sub][name]["下游"].selection.map((item)=>{
            return {
              descname: item,
              selection: _this.indusSelections[sup][sub][name]["下游"][item].selection.map((cate)=>{
                return {
                  catename: cate,
                  selection: _this.indusSelections[sup][sub][name]["下游"][item][cate].selection
                };
              })
            };
          });
        }
        
        // this.currentInsCate = {
        //   name: '',
        //   selection: '' 
        // };
      }
    },
    addWarningForm(formType,actionsType) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:actionsType,
          warningFormType: formType,//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    editRelatedNews() {
      this.$router.push({
        name: "relatedNews",
        query: {
          caseStatus:'',
          warningFormType: 'people',//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    changeDataIdOrName(){
      if(this.searChsanSearchType==='ListID'){
        this.listQuery.ListID = [];
        delete this.listQuery.SearchContent;
      }else if(this.searChsanSearchType==='Name'){
        delete this.listQuery.ListID;
        this.searChsanInputListID = ""
      }else{
        delete this.listQuery.ListID;
        delete this.listQuery.SearchContent;
        this.searChsanInputListID = ""
      }
    },
    isUserDelete(){
      let keys = Object.keys(this.listQuery);
      keys.forEach((key)=>{
        if(key==='IsUser'){
          delete this.listQuery[key]
        }
      })
    },
    changeTypeNews(){
      if(this.typeNews!==this.newsSelectValue){
        this.typeNews = this.newsSelectValue  
        this.listQuery = JSON.parse(JSON.stringify(requestData))
        this.rangeDate = [];
      }
      if(this.newsSelectValue==='searchConstr'){
        this.placeholder = '請輸入廠商名稱'
      }else if(this.newsSelectValue==='searchLabor'){
        this.placeholder = '請輸入單位名稱'
      }else if(this.newsSelectValue==='searchEnviroment'){
        this.placeholder = '請輸入行為人名稱'
      }
      this.isUserDelete()
      this.getList()
    },
    handleSearch(){
      this.listQuery.Page_Num = 1
      this.listQuery.IsUser = true
      this.getList()
    },
    getWebSiteOptions() {
      return new Promise((resolve)=>{
        let temp = {
          Type: ['NewsSite'],
        };
        this.$api.system.getOptions(temp).then((res) => {
          this.webSiteOptions = res.NewsSite;
          resolve()
        });
      })
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    getList() {
      this.$store.dispatch('loadingHandler', true);
      if(this.typeNews==='searChsan' && this.searChsanInputListID){
        this.listQuery.ListID = [];
        this.listQuery.ListID.push(this.searChsanInputListID)
      }
      this.deleteNullValue();
      this.$api.data[this.typeNews](this.listQuery).then((res) => {
        this.tableData = [];
        this.totalLists = res.total;
        if(this.typeNews==='searChsan'){
          this.tableData = res.data.map((item)=>item.SanList)
          this.tableData.forEach((item,index)=>{
            // this.tableData[index].push({})
            item.combination = `${item.FirstName}${" "}${item.SecondName}${" "}${item.ThirdName}`
          })

        }else if(this.typeNews==='searchNews'){
          let order = 1;
          res.data.forEach((item) => {
            item.order = order;
            order++;
          });
          this.tableData = res.data;
        }
        else{
          this.tableData = res.data;
        }
        this.$store.dispatch('loadingHandler', false);
        this.makeTableShowChecked()
      });
    },
    makeTableShowChecked(){
      this.$nextTick(()=>{
        this.tableData.forEach((item) => {
          this.manageAllSelection[this.typeNews].forEach((selectedItem) => {
            if (item.DataID === selectedItem.DataID) {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      })
    },
    /*手動勾選數據Checkbox所觸發的event*/
    getSelectedRow(selection,row){
      let manageAllDataID = this.manageAllSelection[this.typeNews].map((item)=>item.DataID);
      let isIncludes = manageAllDataID.includes(row.DataID);
      if(isIncludes){
        // delete
        let index = manageAllDataID.indexOf(row.DataID)
        this.manageAllSelection[this.typeNews].splice(index,1)
      }else{
        //push
        this.manageAllSelection[this.typeNews].push(row)
      }
    },
    previewDetailPage(item,typeDetail,typeNews){
      const route = this.$router.resolve({
        name:typeDetail,
        query: {
          DataID:item.DataID,
          typeNews:typeNews
        }
      })
      window.open(route.href,'_blank')
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.isUserDelete();
      this.getList(this.typeNews);
    }, 
  },
  async mounted() {
    await this.getWebSiteOptions();
    await this.getIndusSelections();
    await this.getList()
  },
};
</script>

<style lang="scss" scoped>
.relatedInd{
  display: flex;
  ul{
    text-align: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li{
      border: solid 1px #000;
      line-height: 2em;
      background-color: #fff;
      &+li{
        padding: 10px;
      }
    }
    button{
      display: block;
      margin-top: 10px;
    }
  }
  
}
.warningInfo {
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  min-height: 100vh;
  padding: 0px 20px 0px 20px;
  .searchWrap {
    .btnWrap{
      display: flex;
      // justify-content: space-between;
      justify-content: end;
      margin: 10px 0px;
    }
    .typeNewsSelect{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      label{
        width: 100px;
        display: block;
        text-align: right;
      }
    }
    .searchNews{
      display: flex;
      justify-content: space-between;
      .searchInputs{
        width: 35%;
      }
      .itemWrap{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label{
          width: 100px;
          display: block;
          text-align: right;
        }
        &:last-child{
          display: flex;
          align-items: center;
          .el-input{
            width: 300px;
          }
        }
      }
    }
    .searChsan{
      .itemWrap{
        display: flex;
        align-items: center;
        margin-top:10px;
        margin-bottom: 10px;
        label{
          width: 100px;
          display: block;
          text-align: right;
        }
        &:last-child{
          display: flex;
          align-items: center;
          .el-input{
            width: 300px;
          }
        }
      }
    }
    .other{
      .itemWrap{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        label{
          width: 120px;
          display: block;
          text-align: right;
        }
        &:last-child{
          display: flex;
          align-items: center;
          .el-input{
            width: 300px;
          }
        }
      }
    }
  }
  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  a{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    
  }
}
</style>
